<template>
  <div>
    <v-container>
      <v-row class="mt-2">
        <v-col cols="12" class="py-0">
          <h2 class="text-secondary header">ประวัติการทำธุรกรรม</h2>
        </v-col>
      </v-row>
    </v-container>
    <div class="mt-6">
      <v-data-table
        :headers="headers"
        :items="transacations"
        :items-per-page="5"
        class="elevation-1"
      >
        <template v-slot:item.orderId="{ item }">
          <a href="">
            {{ item.orderId }}
          </a>
        </template>
        <template v-slot:item.transactionDatetime="{ item }">
          {{ item.transactionDatetime | formatDate }}
        </template>
        <template v-slot:item.amount="{ item }">
          {{ item.amount }}
        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
import axios from "../../utils/axios.js";
export default {
  data() {
    return {
      loading: false,
      headers: [
        { text: "วันที่ทำรายการ", value: "transactionDatetime" },
        {
          text: "Transaction Ref",
          align: "start",
          sortable: false,
          value: "transactionRef",
        },
        { text: "หมายเลขออเดอร์", value: "orderId" },
        { text: "จำนวนเงิน", value: "amount" },
        { text: "Card Type", value: "cardType" },
      ],
      transacations: [],
    };
  },
  mounted() {
    this.getAllTransactions();
    // console.log(this.genAmountFor2c2p("000001000000"));
  },
  methods: {
    genAmountFor2c2p(payload) {
      const amount = parseFloat(payload).toFixed(2);
      const num = parseInt(amount.split(".").join(""));
      return num.toLocaleString(undefined, {
        minimumIntegerDigits: 12,
        useGrouping: false,
      });
    },
    async getAllTransactions() {
      try {
        this.loading = true;
        const {
          data: { data },
        } = await axios.get("/public/transacation-payment");
        this.transacations = data;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
